@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
}

.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width: 10px;
}

/*  Track  */
::-webkit-scrollbar-track{
  background: #e0e0e0;
}

/*  Handle  */
::-webkit-scrollbar-thumb {
  background: #888;

}

/* Handle on Hover */
::-webkit-scrollbar-thumb:hover{
  background: #555;
}
